/** @format */

import React, {useState} from 'react';
import Footer from '../components/organisms/footer/footer';
import {Box, Button, Flex, Text, useColorMode} from '@chakra-ui/react';
import {BulbLogo} from '../components/molecules/main-menu-nav/bulb-logo';
import MenuButton from '../components/atoms/buttons/menu-button/menu-button';
import LineLabel from '../components/atoms/labels/label-with-line/line-label';
import styles from '../styles/scss/404.module.scss';
import MainMenu from '../components/organisms/main-menu/main-menu';
import Rocket from '../icons/rocket';
import Link from 'next/link';
import {getMainMenu} from '../lib/api/option-pages';
import Patterns from '../patterns/patterns';
import {footerData} from '../utils';
import Layout from '../components/Layout';
import {NextSeo} from 'next-seo';

interface Custom404Props {
	seo: any;
	post: {
		pageLayout: {
			flexibleContent: [Record<string, unknown>];
		};
	};
	fields: [Record<string, unknown>];
}

const Custom404 = (props: Custom404Props) => {
	if (!props) return <div>Error</div>;

	const {colorMode} = useColorMode();

	const [showMainMenu, setShowMainMenu] = useState(false);

	function _handleOnClick() {
		setShowMainMenu(!showMainMenu);
	}

	return (
		<Layout
			seo={{
				title: props.seo.title,
				description: props.seo.description,
			}}>
			<NextSeo {...props.seo} />
			<>
				{showMainMenu && <MainMenu onClick={_handleOnClick} />}
				<>
					<Box className={`wrapper`}>
						<Box
							display={'flex'}
							alignItems={'center'}
							justifyContent={'space-between'}>
							<Link href={'/'}>
								<a>
									<Box cursor={'pointer'}>
										{colorMode === 'dark' ? (
											<BulbLogo fill='white' />
										) : (
											<BulbLogo />
										)}
									</Box>
								</a>
							</Link>
							<MenuButton labelText='Menu' onClick={_handleOnClick} />
						</Box>

						{props.fields?.map((post: any, index: number) => {
							return (
								<Patterns
									fieldGroupName={post.__typename}
									post={post}
									colorMode={colorMode}
									key={`${post.__typename}_${index + 1}`}
								/>
							);
						})}

						<Flex
							justifyContent={'space-around'}
							className={styles.textAndRocketWrapper}>
							<Flex
								flexDirection='column'
								paddingTop='104px'
								width={'400px'}
								className={styles.contentWrapper}>
								<Box paddingBottom='32px'>
									<LineLabel labelText={'404 | Page not found'} />
								</Box>
								<Text
									className={styles.heroText}
									textStyle={{base: 'heading2Medium'}}>
									{'We said pioneering, not perfect.'}
								</Text>
								<Text
									className={styles.paragraph}
									textStyle={{base: 'paragraphMDRegular'}}
									paddingTop={'32px'}>
									{
										'We can’t seem to find the page you’re looking for. Let’s get you to where you need to be.'
									}
								</Text>
								<Flex
									paddingTop={'32px'}
									justifyContent={'space-between'}
									className={styles.buttonWrapper}>
									<Link href={'/'}>
										<a>
											<Button variant='primary'>Go to home</Button>
										</a>
									</Link>
									<Link href={'/contact'} passHref>
										<a>
											<Button variant='secondary'>Contact us</Button>
										</a>
									</Link>
								</Flex>
							</Flex>
							<Box
								maxW={'411px'}
								maxH={'628px'}
								paddingTop={'108px'}
								className={styles.rocketWrapper}>
								<Rocket />
							</Box>
						</Flex>
					</Box>
					<Footer footerData={footerData} />
				</>
			</>
		</Layout>
	);
};

export async function getStaticProps() {
	const mainMenu = await getMainMenu();
	let fields = [];
	fields.unshift(mainMenu);

	return {
		props: {
			fields,
			seo: {
				title: '404 Not Found | Bulb Studios',
				description:
					'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.',
				noindex: true,
			},
		},
	};
}
export default Custom404;
