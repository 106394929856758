import * as React from "react";

function MenuButtonIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6a1 1 0 000 2h22a1 1 0 100-2H1zm0 10a1 1 0 100 2h14a1 1 0 100-2H1z"
        fill="#121D33"
        {...props}
      />
    </svg>
  );
}

export default MenuButtonIcon;
