import * as React from "react";

function Rocket() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 411 628" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M332.479 530.387C327.014 543.752 290.339 542.051 286.089 553.472C281.838 564.893 319.242 563.799 318.028 578.136C316.813 592.473 294.225 594.66 253.664 596.968C213.103 599.277 204.117 609.969 163.556 623.212C122.995 636.455 105.143 619.324 82.4336 606.931C59.8457 594.66 16.4915 617.258 2.89022 598.183C-10.7111 578.987 26.3282 572.183 58.9956 564.771C91.7845 557.238 76.9688 553.715 76.1187 537.799C75.9972 534.518 76.9688 531.967 78.7904 529.901C86.1982 521.882 107.693 521.761 133.438 522.247C165.863 522.976 174 512.162 219.175 513.134C260.829 513.985 326.164 516.536 332.115 527.35C332.843 528.322 332.965 529.294 332.479 530.387Z" fill="#59D8C9"/>
    <path d="M395.869 554.079C395.505 554.808 394.897 555.537 394.169 556.388C388.947 562.098 371.824 565.622 358.101 565.865C341.099 565.986 323.369 564.893 322.762 554.565C322.276 544.238 365.752 543.631 380.932 545.331C394.047 546.546 398.176 549.584 395.869 554.079Z" fill="#59D8C9"/>
    <path d="M59.8468 552.378C59.6039 552.986 59.1182 553.593 58.511 554.201C54.382 558.696 40.9021 561.612 30.0939 561.734C16.7355 561.855 2.64846 561.005 2.28413 552.621C1.91981 544.36 36.166 543.874 48.0671 545.21C58.3895 546.304 61.7898 548.733 59.8468 552.378Z" fill="#59D8C9"/>
    <path d="M277.344 617.137C265.443 621.876 220.996 622.118 221.117 616.287C221.117 615.558 221.36 614.829 221.846 614.221C225.125 609.604 238.726 607.053 250.992 606.567C265.443 606.081 275.28 607.66 279.652 609.969C283.659 612.156 283.052 614.829 277.344 617.137Z" fill="#59D8C9"/>
    <path opacity="0.2" d="M163.92 602.679C152.99 606.688 118.258 605.716 118.501 598.548C118.501 597.697 118.744 596.847 119.108 595.996C122.023 590.407 131.131 588.342 141.575 587.977C153.719 587.491 163.677 589.435 167.685 591.744C172.785 594.538 172.906 599.277 163.92 602.679Z" fill="#171D29"/>
    <path opacity="0.2" d="M298.597 534.518C292.161 537.677 268.723 537.434 268.844 533.303C268.844 532.817 268.966 532.331 269.209 531.845C271.03 528.686 278.195 526.985 284.753 526.742C292.404 526.499 297.626 527.714 299.811 529.415C301.997 530.995 301.633 532.939 298.597 534.518Z" fill="#171D29"/>
    <path opacity="0.2" d="M292.281 578.015C287.059 580.566 267.871 580.323 267.993 577.043C267.993 576.678 268.114 576.192 268.357 575.828C269.814 573.276 275.765 571.818 281.108 571.697C287.423 571.454 291.674 572.547 293.495 573.884C295.074 575.22 294.831 576.8 292.281 578.015Z" fill="#171D29"/>
    <path opacity="0.2" d="M234.236 575.949C179.102 591.136 96.4011 565.865 97.1298 551.406C97.2512 549.584 97.9799 548.005 99.3157 546.547C108.788 536.098 147.406 533.182 182.502 535.247C223.671 537.799 251.48 544.36 263.624 551.406C274.675 557.603 262.41 568.173 234.236 575.949Z" fill="#171D29"/>
    <path opacity="0.2" d="M94.2164 579.959C88.9945 582.875 69.5641 582.632 69.8069 578.865C69.8069 578.379 69.9284 578.015 70.1713 577.529C71.6285 574.613 77.5791 573.033 82.9225 572.79C89.2374 572.547 93.4878 573.641 95.3094 575.22C97.0096 576.8 96.7667 578.622 94.2164 579.959Z" fill="#171D29"/>
    <path d="M271.271 153.013C271.271 153.013 268.114 143.172 279.043 142.565C289.973 142.079 279.043 125.919 289.973 116.564C300.902 107.209 313.897 111.339 314.382 123.854C314.868 136.368 312.804 144.144 314.382 147.303C315.961 150.462 320.576 141.593 326.283 147.789C331.991 153.985 296.652 273.661 296.652 273.661L277.465 268.923L271.271 153.013Z" fill="#F2F3F5"/>
    <path d="M306.004 172.089C300.782 174.519 290.096 245.96 298.961 238.913C301.025 237.333 319.363 165.892 306.004 172.089Z" fill="#B4B5B8"/>
    <path d="M396.477 96.6382C395.991 100.04 395.141 103.199 394.291 105.994C385.79 131.751 362.595 132.723 362.23 136.247C361.866 140.135 378.989 153.013 362.959 168.687C346.929 184.36 314.262 164.434 305.639 163.705C293.495 162.733 268.236 184.36 250.87 161.883C239.697 147.424 257.67 139.284 253.541 135.275C249.412 131.265 217.109 130.536 217.109 94.2082C217.109 93.4792 217.109 92.7502 217.109 92.0212C218.324 55.6932 250.627 58.2447 252.206 51.0763C253.784 43.9079 253.42 1.86949 304.425 0.0470107C355.551 -1.77546 353.973 49.9828 358.587 52.4128C363.445 54.9643 401.941 61.6467 396.477 96.6382Z" fill="#F2F3F5"/>
    <path d="M355.916 126.891C366.846 122.396 383.24 114.377 394.17 105.994C385.669 131.751 362.474 132.723 362.109 136.247C361.745 140.135 378.868 153.013 362.838 168.687C346.808 184.36 314.14 164.434 305.518 163.705C293.374 162.733 268.115 184.36 250.749 161.883C239.576 147.424 257.549 139.284 253.42 135.275C249.291 131.265 216.988 130.536 216.988 94.2082C219.781 99.9186 225.611 108.059 238.119 113.769C259.128 123.368 273.944 112.676 281.352 123.003C288.759 133.331 273.215 139.77 280.137 145.845C287.181 151.92 298.11 136.611 315.598 144.751C333.207 152.892 344.136 158.238 351.058 150.826C358.345 143.294 340.372 133.331 355.916 126.891Z" fill="#B4B5B8"/>
    <path d="M272.974 75.2545C272.974 75.2545 272.853 75.133 272.489 74.89C272.124 74.647 271.76 74.2825 271.153 73.675C270.06 72.5815 268.603 70.8805 267.388 68.5721C266.781 67.4786 266.174 66.1421 265.688 64.6841C265.202 63.2262 264.959 61.6467 264.716 59.9457C264.595 58.2447 264.595 56.5437 264.838 54.8428C264.959 53.9923 265.081 53.1418 265.324 52.2913C265.445 51.8053 265.445 51.5623 265.688 50.8333L266.052 49.6183C266.417 48.1604 267.388 46.3379 268.36 44.7584C269.331 43.3004 270.667 41.8424 272.003 40.749C273.46 39.6555 274.917 38.805 276.375 38.1975C277.832 37.59 279.411 37.2255 280.747 36.9825C282.082 36.7395 283.418 36.7395 284.511 36.7395C285.604 36.7395 286.576 36.7395 287.426 36.861C288.276 36.9825 288.883 36.9825 289.247 37.104C289.733 37.2255 289.976 37.2255 289.976 37.2255C291.555 37.59 292.526 39.048 292.162 40.6275C291.798 42.2069 290.34 43.1789 288.762 42.8144H288.64H288.397C288.397 42.8144 288.276 42.8144 288.033 42.6929C287.669 42.5714 287.304 42.4499 286.576 42.4499C285.969 42.3284 285.24 42.2069 284.268 42.2069C283.418 42.2069 282.447 42.0854 281.354 42.2069C280.261 42.3284 279.168 42.4499 278.075 42.9359C276.982 43.3004 275.768 43.7864 274.796 44.5154C273.703 45.2444 272.732 46.2164 271.881 47.3099C271.031 48.4034 270.424 49.3753 269.817 51.0763L269.331 52.1698L269.088 53.1418C268.845 53.8708 268.603 54.5998 268.603 55.3288C268.36 56.7867 268.238 58.2447 268.238 59.5812C268.238 60.9177 268.481 62.3757 268.724 63.5906C268.967 64.8056 269.453 66.0206 269.938 66.9926C270.91 69.0581 272.124 70.6375 272.974 71.6095C273.46 72.0955 273.825 72.46 274.067 72.703C274.31 72.946 274.432 73.0675 274.432 73.0675H274.553C275.039 73.5535 275.16 74.404 274.675 74.89C274.31 75.4975 273.582 75.619 272.974 75.2545Z" fill="#B4B5B8"/>
    <path d="M287.669 39.534C287.669 39.534 287.669 39.291 287.791 38.6835C287.912 38.1975 288.155 37.4685 288.398 36.4965C289.005 34.6741 289.977 32.1226 292.041 29.3281C293.013 27.9916 294.349 26.5337 295.927 25.4402C297.506 24.2252 299.328 23.2532 301.271 22.4027C302.242 22.0382 303.335 21.7952 304.428 21.4307C305.521 21.1877 306.614 21.0662 307.707 20.9447C308.8 20.8232 309.893 20.8232 310.986 20.8232H311.836H311.957H312.322H312.443H312.808L314.265 20.9447C314.751 21.0662 315.115 21.0662 315.722 21.1877L317.544 21.6737C318.151 21.9167 318.758 22.0382 319.365 22.2812L320.944 23.0102C322.037 23.4962 323.008 24.1037 323.859 24.8327C324.83 25.4402 325.559 26.2907 326.409 27.0197C329.445 30.1786 331.145 33.9451 332.117 36.9825C333.088 40.1415 333.574 42.8144 333.817 44.6369C333.938 45.6089 333.938 46.3379 334.06 46.8239C334.06 47.4314 334.06 47.6744 334.06 47.6744C334.181 49.8613 332.481 51.6838 330.295 51.6838C328.109 51.8053 326.287 50.1043 326.287 47.9174V47.7959V47.5529C326.287 47.5529 326.287 47.4314 326.287 47.0669C326.287 46.7024 326.287 46.0949 326.166 45.3659C326.045 43.9079 325.802 41.5995 325.073 39.1695C324.344 36.7395 323.13 33.9451 321.187 31.7581C320.701 31.2721 320.215 30.6646 319.608 30.1786C319.001 29.6926 318.394 29.2066 317.787 28.9636L316.815 28.3561C316.451 28.2346 316.208 28.1131 315.844 27.9916L314.872 27.6271C314.508 27.5057 313.9 27.5057 313.536 27.3842L312.079 27.1412H311.715H311.593C311.35 27.1412 311.957 27.1412 311.836 27.1412H311.715H311.107C310.257 27.1412 309.407 27.0197 308.557 27.1412C307.707 27.2627 306.857 27.2627 306.128 27.3842C305.4 27.6271 304.55 27.7486 303.821 27.9916C302.364 28.4776 301.028 29.0851 299.935 29.9356C298.842 30.6646 297.87 31.6366 297.142 32.6086C295.563 34.5526 294.713 36.4965 294.227 37.9545C293.984 38.6835 293.863 39.1695 293.741 39.534C293.62 39.8985 293.62 40.02 293.62 40.02V40.263C293.256 41.8425 291.798 42.6929 290.22 42.4499C288.277 42.4499 287.427 40.992 287.669 39.534Z" fill="#B4B5B8"/>
    <path d="M348.02 57.3942C348.02 57.3942 348.87 57.3942 350.206 57.5157C351.542 57.6372 353.363 57.8802 355.549 58.4877C357.735 59.0952 360.285 60.0672 362.714 62.0112C363.929 62.9831 365.022 64.0766 365.993 65.4131C366.479 66.1421 366.843 66.7496 367.207 67.6001L367.693 68.6936L367.936 69.9086C368.3 71.1235 368.665 72.946 368.665 74.404C368.786 75.9835 368.665 77.4414 368.3 78.7779C367.693 81.5724 366.6 84.0024 365.386 85.8248C364.171 87.6473 362.836 88.9838 361.986 89.8343C361.014 90.6848 360.407 91.1708 360.407 91.1708C359.8 91.6568 358.828 91.5353 358.342 90.9278C357.857 90.3203 357.978 89.3483 358.585 88.8623C358.464 88.9838 360.65 87.2828 362.471 84.0024C363.443 82.4229 364.171 80.3574 364.414 78.0489C364.536 76.9555 364.536 75.7405 364.414 74.647C364.293 73.432 364.05 72.5815 363.564 71.1235L363.321 70.273L362.957 69.5441C362.714 69.0581 362.35 68.5721 362.107 68.2076C361.5 67.3571 360.65 66.7496 359.8 66.1421C357.978 65.0486 356.035 64.3196 354.213 63.9551C352.392 63.5906 350.934 63.4691 349.841 63.4691C348.749 63.4691 348.263 63.4691 348.263 63.4691C346.563 63.4691 345.105 62.1327 345.105 60.4317C344.984 58.8522 346.32 57.3942 348.02 57.3942Z" fill="#B4B5B8"/>
    <path d="M267.385 72.217C267.385 72.217 267.264 72.217 267.143 72.217C266.9 72.217 266.657 72.3385 266.292 72.46C265.442 72.703 264.349 73.0675 263.135 73.918C262.528 74.2825 261.921 74.7685 261.435 75.376C260.828 75.9835 260.342 76.7125 259.978 77.5629C259.613 78.4134 259.249 79.2639 259.006 80.3574C258.763 81.3294 258.642 82.5444 258.642 83.5164C258.642 84.8528 258.763 85.7033 259.006 86.6753C259.249 87.6473 259.613 88.6193 260.22 89.4698C261.313 91.1708 262.771 92.3857 264.228 93.2362C265.685 94.0867 267.021 94.5727 267.993 94.8157C268.964 95.0587 269.571 95.1802 269.45 95.1802C270.057 95.1802 270.543 95.7877 270.421 96.3952C270.421 97.0027 269.814 97.4887 269.207 97.3672C269.207 97.3672 268.478 97.3672 267.385 97.1242C266.292 96.8812 264.714 96.5167 262.771 95.7877C260.949 94.9372 258.885 93.6007 257.184 91.5353C256.334 90.4418 255.727 89.2268 255.241 88.0118C254.999 87.4043 254.877 86.5538 254.634 85.9463C254.391 85.2173 254.391 84.7313 254.27 84.0024C254.27 83.6379 254.148 83.5164 254.148 82.9089V81.9369C254.148 81.2079 254.148 80.6004 254.27 79.8714C254.391 78.5349 254.634 77.077 255.12 75.862C255.606 74.5255 256.213 73.3105 257.063 72.3385C257.792 71.245 258.642 70.3945 259.613 69.6656C261.313 68.2076 263.014 67.4786 264.228 66.9926C264.835 66.7496 265.321 66.6281 265.685 66.5066C266.05 66.3851 266.292 66.3851 266.292 66.3851C267.993 66.0206 269.693 66.9926 270.057 68.6936C270.421 70.3945 269.45 72.0955 267.75 72.46C267.628 72.217 267.507 72.217 267.385 72.217Z" fill="#B4B5B8"/>
    <path d="M341.829 108.91C341.829 108.91 341.829 109.76 341.587 111.096C341.344 112.433 340.979 114.255 340.251 116.442C339.522 118.629 338.429 121.181 336.365 123.489C335.393 124.583 334.179 125.676 332.721 126.527C331.993 126.891 331.264 127.377 330.536 127.62L329.321 127.985L328.228 128.228C327.014 128.592 325.192 128.714 323.735 128.714C322.156 128.714 320.699 128.471 319.363 128.228C316.57 127.499 314.263 126.284 312.441 124.947C310.619 123.611 309.405 122.274 308.555 121.302C307.705 120.33 307.34 119.723 307.34 119.601C306.855 118.994 307.098 118.022 307.705 117.536C308.312 117.05 309.283 117.293 309.769 117.9C309.769 117.779 311.227 119.966 314.505 122.031C316.084 123.003 318.149 123.975 320.335 124.34C321.428 124.583 322.642 124.583 323.735 124.461C324.949 124.34 325.799 124.218 327.257 123.732L328.107 123.489L328.835 123.125C329.321 122.882 329.807 122.639 330.171 122.274C331.021 121.667 331.75 120.816 332.357 120.087C333.572 118.386 334.3 116.442 334.786 114.62C335.272 112.797 335.393 111.339 335.515 110.246C335.636 109.153 335.515 108.667 335.515 108.667C335.515 106.966 336.972 105.629 338.672 105.629C340.494 105.751 341.951 107.087 341.829 108.91Z" fill="#B4B5B8"/>
    <path d="M245.41 209.389L240.31 195.173C240.31 195.173 252.089 193.351 245.896 177.921C242.374 169.294 232.416 176.098 232.416 176.098C232.416 176.098 230.716 163.584 217.722 166.378C207.521 168.565 207.278 184.967 211.285 190.192C215.172 195.416 219.179 194.93 219.179 194.93C219.179 194.93 210.678 194.687 212.014 202.463C213.35 210.239 220.029 210.604 225.13 208.052C227.558 206.837 229.38 203.8 229.38 203.8L240.431 212.912C241.403 213.763 242.86 213.884 244.074 213.277C245.41 212.669 246.017 210.968 245.41 209.389Z" fill="#F2F3F5"/>
    <path d="M366.602 241.221C366.116 243.53 365.266 245.595 364.295 246.689C361.38 250.091 356.765 250.455 356.765 250.455C356.765 250.455 360.894 252.764 357.858 257.745C355.551 261.39 348.75 259.203 346.929 256.894C344.986 254.586 344.986 251.913 344.986 251.913L336.728 258.96C335.392 260.175 333.206 260.053 331.992 258.595C330.899 257.38 330.899 255.436 331.992 254.221L333.085 253.007L339.764 245.109C339.764 245.109 330.291 241.221 333.692 234.174C336.728 227.735 342.8 230.772 344.378 232.473C345.836 234.053 346.079 236.726 346.079 236.726C346.079 236.726 348.872 225.912 358.951 228.342C366.481 230.043 367.574 236.24 366.602 241.221Z" fill="#F2F3F5"/>
    <path d="M108.421 389.814C110.364 392.487 110.486 396.132 108.542 398.926L107.814 399.898C107.085 400.87 105.992 401.356 104.899 401.356C104.049 401.356 103.321 401.113 102.592 400.505L90.9336 390.3C90.9336 390.3 85.2259 403.543 69.6816 400.505C58.8734 398.318 56.0803 389.085 57.2947 381.43C57.7805 377.664 59.2377 374.262 61.1808 372.196C64.5811 368.551 68.953 368.065 72.1104 368.187C68.953 367.215 64.4597 364.663 62.7595 358.346C61.6665 354.336 61.9094 350.327 63.3667 347.168C65.6741 342.186 70.896 339.027 78.3039 339.878C90.5693 341.457 90.8122 358.832 90.8122 358.832C90.8122 358.832 95.0626 347.046 106.721 352.149C116.922 356.645 114.007 370.252 107.085 375.598C103.442 378.393 100.285 379 100.285 379L108.421 389.814Z" fill="#F2F3F5"/>
    <path d="M82.0664 370.374C82.0664 370.374 77.0873 369.402 72.2297 368.065C69.0723 367.093 64.579 364.663 62.7574 358.224C61.6644 354.215 61.9073 350.205 63.3646 347.046C64.0932 351.299 66.0362 357.981 70.7724 362.355C78.1803 369.159 82.0664 370.374 82.0664 370.374Z" fill="#B4B5B8"/>
    <path d="M107.814 399.776C107.085 400.748 105.992 401.234 104.899 401.234C104.049 401.234 103.321 400.991 102.592 400.384L90.9336 390.178C90.9336 390.178 85.2259 403.421 69.6816 400.384C58.8734 398.197 56.0803 388.963 57.2947 381.309C58.9949 384.954 62.7595 389.085 71.3818 390.543C88.6263 393.58 89.112 382.767 89.112 382.767L107.814 399.776Z" fill="#B4B5B8"/>
    <path d="M154.086 362.962C152.508 362.962 147.286 362.598 142.549 358.224C137.813 353.85 135.385 346.925 135.263 337.812C135.02 316.307 130.891 313.391 126.398 312.055C120.083 310.232 113.282 312.662 113.282 312.662C110.854 313.634 108.182 312.419 107.21 309.989C106.239 307.559 107.453 304.886 109.882 303.914C110.246 303.793 119.476 300.269 128.948 302.942C141.578 306.587 144.371 318.859 144.493 337.569C144.614 343.887 146.071 348.626 148.622 351.177C151.05 353.486 153.843 353.486 153.843 353.486C156.394 353.243 158.58 355.43 158.701 357.981C158.822 360.533 156.879 362.719 154.329 362.841C154.451 362.962 154.329 362.962 154.086 362.962Z" fill="#121D33"/>
    <path d="M150.323 297.596C149.108 298.69 145.586 302.578 145.101 308.896C144.736 315.335 147.651 322.018 153.844 328.821C168.296 344.616 167.446 349.598 165.017 353.607C161.617 359.318 155.059 362.112 155.059 362.112C152.63 363.084 151.537 365.757 152.509 368.187C153.48 370.617 156.152 371.71 158.581 370.738C158.945 370.617 168.174 366.85 173.153 358.346C179.833 346.925 173.396 336.233 160.888 322.382C156.637 317.765 154.452 313.27 154.573 309.625C154.694 306.223 156.759 304.4 156.88 304.279C158.823 302.699 158.945 299.662 157.245 297.718C155.545 295.774 152.751 295.531 150.687 297.232C150.565 297.353 150.444 297.475 150.323 297.596Z" fill="#121D33"/>
    <path d="M365.512 325.42C360.897 325.42 355.797 323.962 351.182 320.317C343.289 314.242 338.067 318.373 338.067 318.494C336.124 320.195 333.088 319.952 331.509 317.887C329.809 315.943 330.052 312.905 332.116 311.326C335.881 308.167 345.96 304.4 357.011 312.905C362.962 317.522 369.277 316.307 371.706 313.999C374.013 311.933 375.349 305.98 375.592 302.456C375.713 299.905 378.021 297.839 380.571 298.082C383.121 298.204 385.064 300.512 384.943 303.064C384.821 304.279 383.971 315.578 378.021 320.924C374.985 323.719 370.491 325.42 365.512 325.42Z" fill="#121D33"/>
    <path d="M363.082 383.253L401.821 426.628C401.821 426.628 390.528 407.188 380.812 394.917C370.611 382.281 363.082 383.253 363.082 383.253Z" fill="#F2F3F5"/>
    <path d="M393.563 403.179C393.563 403.179 383.605 391.272 382.148 389.449C378.262 384.711 375.469 383.253 375.469 383.253C375.469 383.253 378.991 382.767 383.605 388.599C388.22 394.309 393.563 403.179 393.563 403.179Z" fill="#F2F3F5"/>
    <path d="M334.184 362.72L351.671 332.588L341.227 360.411L356.65 375.72L334.184 362.72Z" fill="#FFC927"/>
    <path d="M361.505 347.654L336.367 361.14L342.561 363.449L361.505 347.654Z" fill="#FFC927"/>
    <path d="M107.576 294.559L98.4682 283.989C97.3753 282.652 97.4967 280.708 98.8325 279.493C100.168 278.4 102.111 278.521 103.326 279.858C103.569 280.101 103.69 280.344 103.812 280.587L109.641 293.222C109.884 293.83 109.641 294.559 109.034 294.802C108.548 295.166 107.941 295.045 107.576 294.559Z" fill="#FFC927"/>
    <path d="M99.1958 306.101L82.0728 307.802C80.3726 307.924 78.7939 306.709 78.6724 305.008C78.551 303.307 79.7654 301.727 81.4655 301.606C81.7084 301.606 81.9513 301.606 82.1942 301.606L99.1958 303.671C99.9245 303.793 100.289 304.4 100.289 305.008C100.167 305.615 99.803 306.101 99.1958 306.101Z" fill="#FFC927"/>
    <path d="M106.726 320.195L98.3467 330.644C97.2537 331.98 95.3107 332.223 93.9749 331.13C92.639 330.036 92.3961 328.093 93.4891 326.756C93.6105 326.513 93.8534 326.27 94.0963 326.149L105.026 318.373C105.633 318.008 106.362 318.13 106.848 318.616C107.212 319.102 107.09 319.709 106.726 320.195Z" fill="#FFC927"/>
    <path d="M157.483 288.363L163.919 279.858C164.769 278.764 166.227 278.643 167.32 279.372C168.413 280.222 168.534 281.68 167.805 282.774C167.684 282.895 167.562 283.138 167.32 283.26L158.819 289.699C158.454 290.064 157.847 289.942 157.483 289.578C157.24 289.213 157.24 288.727 157.483 288.363Z" fill="#FFC927"/>
    <path d="M147.522 283.989L143.393 271.474C143.029 270.259 143.636 268.801 144.972 268.437C146.186 268.072 147.644 268.68 148.008 270.016C148.008 270.138 148.129 270.381 148.129 270.502L149.344 283.624C149.344 284.11 148.979 284.596 148.494 284.596C148.008 284.596 147.644 284.353 147.522 283.989Z" fill="#FFC927"/>
    <path d="M138.174 291.886L128.944 287.269C127.73 286.662 127.244 285.204 127.851 283.989C128.458 282.774 129.916 282.288 131.13 282.895C131.373 283.017 131.494 283.138 131.737 283.26L139.388 290.185C139.752 290.55 139.874 291.157 139.51 291.643C139.024 292.008 138.538 292.129 138.174 291.886Z" fill="#FFC927"/>
    <path d="M383.119 276.091L380.933 288.606C380.812 289.335 380.205 289.699 379.476 289.578C378.869 289.456 378.504 288.97 378.383 288.484L376.804 275.848C376.561 274.147 377.776 272.568 379.597 272.325C381.298 272.082 382.876 273.297 383.119 275.119C383.241 275.484 383.241 275.727 383.119 276.091Z" fill="#FFC927"/>
    <path d="M405.101 287.877L392.35 296.26C391.5 296.746 390.407 296.624 389.921 295.774C389.435 295.045 389.557 294.073 390.164 293.465L401.336 283.017C402.551 281.802 404.615 281.923 405.708 283.138C406.922 284.353 406.801 286.419 405.587 287.512C405.344 287.634 405.222 287.755 405.101 287.877Z" fill="#FFC927"/>
    <path d="M403.036 314.606L392.106 309.382C391.377 309.017 391.013 308.167 391.377 307.316C391.62 306.709 392.349 306.344 392.956 306.466L404.857 308.531C406.557 308.774 407.772 310.475 407.407 312.176C407.165 313.877 405.464 315.092 403.764 314.728C403.643 314.849 403.4 314.728 403.036 314.606Z" fill="#FFC927"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M231.926 255.072C231.926 255.072 180.8 316.307 178.857 314.728C178.371 314.363 177.521 311.569 176.428 307.073C173.513 294.316 169.506 267.586 169.02 239.156C168.413 200.884 169.384 189.22 179.585 184.481C187.115 180.958 207.638 214.856 221.118 237.576C225.976 245.352 229.74 251.792 231.926 255.072Z" fill="#008292"/>
    <path opacity="0.05" d="M231.928 255.072C231.928 255.072 180.802 316.307 178.858 314.728C178.373 314.363 177.523 311.569 176.43 307.073C181.409 294.316 187.481 280.83 194.16 270.624C204.968 254.343 214.683 243.773 221.12 237.576C225.977 245.352 229.742 251.792 231.928 255.072Z" fill="#171D29"/>
    <path d="M408.986 465.629C405.829 472.19 376.562 463.077 352.638 454.815C348.023 453.236 343.53 451.656 339.401 450.198C329.929 446.918 323.614 437.927 323.614 427.843C323.735 404.393 321.549 367.094 326.043 365.15C331.386 362.72 357.86 388.842 382.027 416.908C407.043 445.946 413.722 455.544 408.986 465.629Z" fill="#008292"/>
    <path opacity="0.05" d="M352.638 454.815C348.023 453.236 343.53 451.656 339.401 450.198C329.929 446.918 323.614 437.927 323.614 427.843C323.735 404.393 321.549 367.094 326.043 365.15C326.164 365.15 336.122 396.01 342.923 420.31C346.809 434.404 350.088 445.946 352.638 454.815Z" fill="#171D29"/>
    <path d="M335.152 304.4C334.302 337.569 329.444 392.73 309.649 447.404C292.526 494.788 277.711 526.256 267.874 544.846C259.737 560.154 255.001 566.958 255.001 566.958C255.001 566.958 242.129 573.398 217.355 575.342C203.511 576.435 186.023 576.192 165.014 572.669C106.237 562.827 100.044 544.359 100.044 544.359C100.044 544.359 99.1935 533.789 101.987 513.013C105.508 486.283 115.224 442.787 140.605 383.374C169.143 316.915 201.689 277.792 221.848 257.988C233.749 246.324 241.4 241.343 241.4 241.343C241.4 241.343 257.187 234.903 289.855 245.109C302.849 249.119 312.685 254.343 319.608 259.324C330.294 266.736 334.666 273.297 334.666 273.297C334.666 273.297 335.759 285.204 335.152 304.4Z" fill="#F4F5F0"/>
    <path opacity="0.05" d="M335.152 304.4C334.302 337.569 329.445 392.73 309.65 447.404C292.527 494.788 277.711 526.256 267.875 544.845C259.738 560.154 255.002 566.958 255.002 566.958C255.002 566.958 242.129 573.398 217.355 575.342C233.143 553.715 256.581 516.172 279.897 459.311C317.787 366.972 320.094 285.811 319.73 259.324C330.416 266.736 334.788 273.297 334.788 273.297C334.788 273.297 335.76 285.204 335.152 304.4Z" fill="#171D29"/>
    <g opacity="0.05">
    <path d="M115.832 507.789C115.468 507.789 115.225 507.789 114.861 507.667C111.46 507.181 109.153 503.901 109.639 500.499C110.003 498.433 117.775 448.376 139.27 397.954C160.765 347.654 177.767 322.99 178.495 321.896C180.438 319.102 184.324 318.373 187.239 320.317C190.032 322.261 190.761 326.149 188.818 329.064C188.696 329.307 171.695 353.85 150.807 402.814C129.919 451.778 122.026 501.835 122.026 502.321C121.418 505.602 118.747 507.789 115.832 507.789Z" fill="#171D29"/>
    </g>
    <path d="M335.151 304.4C330.9 300.634 325.435 296.26 318.878 291.764C316.206 289.942 313.291 287.998 310.134 286.054C308.434 285.082 306.734 283.989 304.912 283.017C297.747 278.886 289.611 274.998 280.745 271.596C280.017 271.353 279.41 271.11 278.681 270.867C277.345 270.381 276.131 269.895 274.916 269.53C264.715 265.885 255.729 263.455 248.078 261.754C245.892 261.268 243.706 260.904 241.763 260.418C233.262 258.838 226.705 258.352 222.09 258.109C233.991 246.446 241.642 241.464 241.642 241.464C241.642 241.464 246.499 239.52 256.457 239.52C258.643 239.52 261.072 239.642 263.744 239.885C270.059 240.371 277.709 241.707 286.696 244.259C287.789 244.623 289.003 244.866 290.096 245.231C290.946 245.474 291.797 245.717 292.647 246.081C299.69 248.511 305.762 251.184 310.863 253.857C312.927 254.95 314.749 256.044 316.57 257.259C317.785 257.988 318.756 258.717 319.849 259.446C330.536 266.857 334.908 273.418 334.908 273.418C334.908 273.418 335.758 285.204 335.151 304.4Z" fill="#D4E157"/>
    <path d="M335.152 304.4C330.901 300.634 325.437 296.26 318.879 291.765C319.85 277.549 319.85 266.493 319.85 259.446C330.537 266.857 334.909 273.418 334.909 273.418C334.909 273.418 335.759 285.204 335.152 304.4Z" fill="#D4E157"/>
    <path d="M316.572 257.259L310.986 284.596C310.865 285.204 310.622 285.69 310.136 286.176C308.436 285.204 306.736 284.11 304.914 283.138L310.865 253.979C312.929 255.072 314.872 256.165 316.572 257.259Z" fill="#D4E157"/>
    <path d="M292.648 246.081L280.747 271.596C280.018 271.353 279.411 271.11 278.683 270.867C277.347 270.381 276.132 269.895 274.918 269.53L286.576 244.259C287.669 244.623 288.884 244.866 289.977 245.231C290.948 245.595 291.798 245.838 292.648 246.081Z" fill="#D4E157"/>
    <path d="M263.746 239.885C263.625 240.128 263.503 240.371 263.382 240.614L248.081 261.754C245.895 261.268 243.709 260.904 241.766 260.418C241.887 259.932 242.009 259.446 242.373 258.96L256.46 239.399C258.646 239.52 261.075 239.642 263.746 239.885Z" fill="#D4E157"/>
    <ellipse opacity="0.1" cx="200.465" cy="457.486" rx="54.6516" ry="75.8122" transform="rotate(18.935 200.465 457.486)" fill="#171D29"/>
    <ellipse cx="204.096" cy="455.181" rx="54.6516" ry="74.9617" transform="rotate(18.935 204.096 455.181)" fill="#008292"/>
    <ellipse cx="202.279" cy="455.18" rx="43.3569" ry="60.6254" transform="rotate(18.935 202.279 455.18)" fill="#F4F5F0"/>
    <ellipse opacity="0.1" cx="199.795" cy="421.453" rx="12.3877" ry="17.3736" transform="rotate(18.935 199.795 421.453)" fill="#171D29"/>
    <ellipse opacity="0.1" cx="182.314" cy="469.069" rx="6.67964" ry="9.23353" transform="rotate(18.935 182.314 469.069)" fill="#171D29"/>
    <path opacity="0.1" d="M243.343 469.274C232.413 500.985 205.332 520.303 182.623 512.527C170.721 508.396 162.342 497.583 158.82 483.61C161.735 485.554 164.892 487.255 168.293 488.349C196.467 498.069 230.349 473.891 243.828 434.404C244.679 431.974 245.407 429.544 246.014 427.114C249.05 439.628 248.443 454.572 243.343 469.274Z" fill="#171D29"/>
    <path d="M267.995 544.967C259.859 560.276 255.122 567.08 255.122 567.08C255.122 567.08 242.25 573.519 217.476 575.463C203.632 576.557 186.144 576.314 165.135 572.79C143.397 569.145 128.946 564.285 119.231 559.668C102.715 551.771 100.165 544.481 100.165 544.481C100.165 544.481 99.3146 533.911 102.108 513.134C107.208 517.873 114.009 523.219 122.995 528.2C136.84 535.855 155.663 543.023 181.287 546.789C200.474 549.584 218.812 549.948 235.206 548.855C247.35 548.247 258.401 546.668 267.995 544.967Z" fill="#D4E157"/>
    <path opacity="0.1" d="M267.996 544.967C259.859 560.276 255.123 567.08 255.123 567.08C255.123 567.08 242.25 573.519 217.477 575.463C222.698 568.295 228.649 559.547 235.207 548.855C247.351 548.247 258.402 546.668 267.996 544.967Z" fill="#171D29"/>
    <path opacity="0.05" d="M122.995 528.322L119.231 559.79C102.715 551.892 100.165 544.603 100.165 544.603C100.165 544.603 99.3146 534.032 102.108 513.256C107.208 517.994 114.009 523.219 122.995 528.322Z" fill="#171D29"/>
    </svg>
    
  );
}

export default Rocket;
