import React, { MouseEventHandler } from "react";
import { Button, chakra } from "@chakra-ui/react";
import MenuButtonIcon from "../../../../icons/icon-menu-button";

interface MenuButtonProps {
  labelText: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
const MenuButton = ({ labelText, onClick }: MenuButtonProps) => {
  return (
    <Button
      type="button"
      width={"122px"}
      height={"48px"}
      borderRadius={"24px"}
      border={"2px solid black"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      background={"white"}
      _hover={{
        backgroundColor: "#121D33",
        color: "white",
        path: { fill: "white" },
      }}
      _focus={{ backgroundColor: "#white", color: "black" }}
      _active={{ border: "2px solid #59D8C9" }}
      onClick={onClick}
    >
      <chakra.span marginRight={"8px"}>{labelText}</chakra.span>
      <MenuButtonIcon />
    </Button>
  );
};
export default MenuButton;
